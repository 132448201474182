import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Password from "../components/GreenRoom/Password";
import ArtworkGenerator from '../components/GreenRoom/ArtworkGenerator';
import { fetchCockpitData } from '../utils/cockpit';
import StreamingInstructions from '../components/GreenRoom/StreamingInstructions';
import HelmetContent from '../utils/HelmetContent';

const GreenRoomContent = (props) => {

  const { windowHeight, passwordIsCorrect } = props;
  const [greenRoom, setGreenRoom] = useState({});

  useEffect(() => {
    if (passwordIsCorrect === true) {
      fetchCockpitData('greenRoom', setGreenRoom, false);
    }
  }, [passwordIsCorrect]);

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB GREEN ROOM';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
      className="green-room"
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        description={'Everything you need to know about streaming on imissmymates.club'}
        title={`Green Room`}
      />
      {
        passwordIsCorrect === true ?
          <div className="green-room__content">
            <ArtworkGenerator {...props} greenRoom={greenRoom} />
            <StreamingInstructions {...props} greenRoom={greenRoom} />
          </div>
          :
          <Password {...props} />
      }
    </motion.div>
  )
}

const GreenRoom = (props) => {

  const { siteOptions } = props;
  const { passwordProtectGreenRoom } = siteOptions;
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);

  if (typeof siteOptions.passwordProtectGreenRoom !== 'undefined') {
    return (
      <GreenRoomContent
        {...props}
        passwordIsCorrect={passwordIsCorrect === true || (typeof passwordProtectGreenRoom !== 'undefined' && passwordProtectGreenRoom === false)}
        setPasswordIsCorrect={setPasswordIsCorrect}
      />
    );
  } else {
    return null;
  }
}

export default GreenRoom;