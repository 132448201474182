import { useEffect } from 'react';
import { motion } from 'framer-motion';
import TwitchPlayer from '../components/TwitchPlayer';
import VideoPlayerLive from '../components/VideoPlayerLive';
import HelmetContent from '../utils/HelmetContent';

const Room1 = (props) => {

  const { windowHeight, room2 } = props;
  const { youtubeUrl, twitchChannel, isTwitch } = room2;

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB ROOM 2';
  }, []);

  return (
    <motion.div
      className="room-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={`Room 2`}
      />
      <div className="room-2__wrapper">
        {
          youtubeUrl && youtubeUrl !== '' && isTwitch === false &&
          <VideoPlayerLive {...props} url={`${youtubeUrl}`} />
        }
        {
          twitchChannel && twitchChannel !== '' && isTwitch === true &&
          <div
            className="video-player__wrapper"
          >
            <TwitchPlayer {...props} twitchChannel={twitchChannel} />
          </div>
        }
      </div>
    </motion.div>
  )
}

export default Room1;