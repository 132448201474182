import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ShowAudioVisualSplat from '../components/ShowAudioVisualSplat';
import ShowAudioVisualMirrorBall from '../components/ShowAudioVisualMirrorBall';
import VideoPlayerShow from '../components/VideoPlayerShow';
import LazyloadImage from '../components/LazyloadImage';
import AudioPlayer from '../components/AudioPlayer';
import ShowInfo from '../components/ShowInfo';
import HelmetContent from '../utils/HelmetContent';

const Show = (props) => {

  const { windowHeight, item } = props;
  const { embed } = item;
  const [random] = useState(Math.round(Math.random()));

  useEffect(() => {
    document.title = `I MISS MY MATES CLUB ${item.title ? item.title.toUpperCase() : ''}`;
  }, [item.title]);

  return (
    <motion.div
      className="show"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={item.title + ' | ' + item.date}
        description={item.description}
      />
      <div className={`show__wrapper${ item.type === 'Audio' ? ' audio' : ' video' }`}>
        {
          item.type === 'Audio' && embed &&
          <div className="show__inner">
            {
              item.image && item.image.path &&
              <LazyloadImage image={item.image} isBackgroundImage={true} />
            }
            <ShowInfo {...props} />
            <div className="show__canvas__wrapper">
              {
                random === 0 ?
                <ShowAudioVisualMirrorBall {...props} />
                  :
                <ShowAudioVisualSplat {...props} />
              }
            </div>
          </div>
        }
        {
          item.type === 'Audio' && embed &&
          <div className="show__audio__wrapper">
            <AudioPlayer {...props} url={item.embed} />
          </div>
        }
        {
          item.type === 'Video' && embed &&
          <VideoPlayerShow {...props} url={embed} />
        }
      </div>
    </motion.div>
  )
}

export default Show;