import CookieConsent from 'react-cookie-consent';

const CookiesNotice = (props) => {

  const { handleAcceptCookies } = props;

  return (
    <div
      className="cookies-notice__wrapper ui-item"
    >
      <CookieConsent
        containerClasses="cookies-notice"
        location="none"
        buttonText="yes babe"
        disableButtonStyles
        hideOnAccept={false}
        disableStyles
        buttonClasses="cookies-notice__option cookies-notice__button cookies-notice__button--accept button"
        buttonWrapperClasses="cookies-notice__options"
        onAccept={handleAcceptCookies}
      >
        <p>By using this site you consent to the use of cookies blah blah blah</p>
      </CookieConsent>
    </div>
  )
}

export default CookiesNotice;