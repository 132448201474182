import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import StartStreamForm from './StartStreamForm';
import Password from './Password';
import parse from 'html-react-parser';
import StreamingAndTracklistForm from './StreamingAndTracklistForm';
import Artwork from '../Artwork';
import CopyTextElement from './CopyTextElement';
import HelmetContent from '../../utils/HelmetContent';

const WheelsOfSteelContent = (props) => {

  const { windowHeight, activeLivestream, streamKey, passwordIsCorrect, wheelsOfSteelInfo, youAreStreaming, youAreConnecting, youHaveFinishedStreaming } = props;
  const [archiveItem, setArchiveItem] = useState({});
  const [imagePreviewPath, setImagePreviewPath] = useState('');

  return (
    <motion.div
      className="dj-booth"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={`Wheels of Steel`}
      />
      {
        passwordIsCorrect === true ?
          <div className="dj-booth__content">
            {
              !activeLivestream.playbackId && youAreStreaming === false && youAreConnecting === false && youHaveFinishedStreaming === false &&
              <div className="dj-booth__livestream">
                {
                  typeof streamKey === 'undefined' || streamKey === '' ?
                  <StartStreamForm {...props} setArchiveItem={setArchiveItem} imagePreviewPath={imagePreviewPath} setImagePreviewPath={setImagePreviewPath} />
                  :
                  <div className="stream-key__wrapper">
                    <Artwork
                      {...props}
                      image={imagePreviewPath}
                      title={archiveItem.title}
                      date={archiveItem.date}
                      startTime={archiveItem.startTime ? archiveItem.startTime : ''}
                      endTime={archiveItem.endTime ? archiveItem.endTime : ''}
                      withButton={true}
                      withMessage={true}
                    />
                    <h3>Server name</h3>
                    <CopyTextElement text={`rtmp://global-live.mux.com:5222/app/`} />
                    <h4>Stream key is</h4>
                    <CopyTextElement text={streamKey} />
                    {
                      wheelsOfSteelInfo.streamingKeyInfo &&
                      <div>{parse(wheelsOfSteelInfo.streamingKeyInfo)}</div>
                    }
                    {
                      wheelsOfSteelInfo.welcomeText &&
                      <div>
                        {parse(wheelsOfSteelInfo.welcomeText)}
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {
              youAreConnecting === true &&
              <p>Patience doll, your stream is connecting</p>
            }
            {
              youAreStreaming === true &&
              <Artwork
                {...props}
                image={imagePreviewPath}
                title={archiveItem.title}
                date={archiveItem.date}
                startTime={archiveItem.startTime ? archiveItem.startTime : ''}
                endTime={archiveItem.endTime ? archiveItem.endTime : ''}
                withButton={true}
                withMessage={true}
              />
            }
            {
              youAreStreaming === true &&
              <StreamingAndTracklistForm {...props} />
            }
            {
              youHaveFinishedStreaming === true &&
              <div className="dj-booth__finished__wrapper">
                <p>{wheelsOfSteelInfo.finishedText}</p>
                <p>If you missed anything out or forgot to upload your tracklist, please add it at <Link to="/submit" className="link">imissmymates.club/submit</Link></p>
                <Artwork
                  {...props}
                  image={imagePreviewPath}
                  title={archiveItem.title}
                  date={archiveItem.date}
                  startTime={archiveItem.startTime ? archiveItem.startTime : ''}
                  endTime={archiveItem.endTime ? archiveItem.endTime : ''}
                  withButton={true}
                  withMessage={true}
                />
              </div>
            }
            {
              activeLivestream.playbackId && youAreStreaming === false &&
              <div className="dj-booth__other-show-in-progress__wrapper">
                <p>{wheelsOfSteelInfo.otherShowInProgressText}</p>
              </div>
            }
          </div>
          :
          <Password {...props} />
      }
    </motion.div>
  )
};

export default WheelsOfSteelContent;