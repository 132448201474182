const WheelsOfSteelImageUploader = (props) => {

  const { setImageValue, setImagePreviewPath } = props;

  const processImage = (event) => {
    const img = document.createElement('img');

    const handleImageLoad = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 2000;
      canvas.height = Math.floor(2000 / img.naturalWidth * img.naturalHeight);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      
      const dataURL = canvas.toDataURL('image/jpeg', 0.3);
      setImagePreviewPath(dataURL);
      
      canvas.toBlob((blob) => {
        const file = new File([blob], `image-at-${new Date().getTime()}.jpg`, { type: "image/jpeg" });
        setImageValue(file);
      }, 'image/jpeg', 0.3);
    }

    img.addEventListener('load', handleImageLoad);

    if (event) {
      if (event.target?.files && event.target.files.length > 0) {
        img.src = URL.createObjectURL(event.target.files[0]);
      }
    }

    return () => {
      img.removeEventListener('load', handleImageLoad);
    }
  }

  return (
    <input
      className="artwork-generator__form__file"
      accept="image/png, image/jpg, image/jpeg, image/gif"
      type="file"
      name="image"
      required={true}
      id="uploadImage"
      onChange={processImage}
    />
  )
};

export default WheelsOfSteelImageUploader;