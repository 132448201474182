import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const CanvasRecorder = (props) => {

  const { setVideoSrc, imageFullPath, setVideoIsReady, socket } = props;
  const { gl } = useThree();
  const { domElement } = gl;

  useEffect(() => {
    let chunks = [];
    const canvas = domElement;

    const videoOutputStream = canvas.captureStream(30);

    const mediaRecorder = new MediaRecorder(videoOutputStream, {
      mimeType: 'video/webm',
      videoBitsPerSecond: 3000000
    });

    const onFinishRecording = () => {
      const blob = new Blob(chunks, { 'type': 'video/webm' });
      const file = new File([blob], `recording_${new Date().getTime()}_.webm`, {
        type: blob.type,
      });

      socket.emit('artwork created', { file: file });

      const videoUrl = window.URL.createObjectURL(blob);
      setVideoSrc(videoUrl);
      setVideoIsReady(true);
      chunks = [];
    }

    const dataToChunks = (e) => {
      chunks.push(e.data);
    }

    mediaRecorder.addEventListener('dataavailable', dataToChunks);

    mediaRecorder.start(1000);
    setVideoIsReady(false);

    setTimeout(() => {
      onFinishRecording();
      mediaRecorder.stop();
    }, 2000);

  }, [domElement, setVideoSrc, imageFullPath, setVideoIsReady, socket]);

  return null;
}

export default CanvasRecorder;