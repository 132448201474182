import { useRef, Suspense } from 'react';
import { cockpitUrl } from '../utils/cockpit';
import { ShowAudioVisualMirrorBallEnvMap } from './ShowAudioVisualMirrorBall';
import { Canvas, useFrame } from '@react-three/fiber';
import { useTexture, useCubeTexture, useProgress } from '@react-three/drei';
import * as THREE from 'three';
import normalTexture from '../../assets/normals2.png';

const MirrorBallMesh = (props) => {

  const { setIsLoading, isLoading, activeLivestream } = props;

  const mirrorBall = useRef();
  const material = useRef();
  const { progress } = useProgress();

  const normalMap = useTexture(normalTexture);
  const envMap = useCubeTexture([
    'px.jpg', 'nx.jpg',
    'py.jpg', 'ny.jpg',
    'pz.jpg', 'nz.jpg'
  ], {path: '/skybox/'});

  useFrame(() => {
    if (progress === 100 && isLoading === true) {
      setIsLoading(false);
    }
    if (mirrorBall.current) {
      mirrorBall.current.rotation.y -= 0.0025;
    }
  });

  return (
    <mesh ref={mirrorBall}>
      <sphereBufferGeometry args={[2.4, 64, 64]} />
      {
        activeLivestream.image?.path && activeLivestream.image?.path !== '' ?
        <meshPhysicalMaterial
          ref={material}
          metalness={1.0}
          roughness={0.0}
          clearcoat={0.9}
          reflectivity={1.0}
          needsUpdate={true}
        >
          <ShowAudioVisualMirrorBallEnvMap
            material={material}
            imageFullPath={cockpitUrl + '/' + activeLivestream.image.path}
          />
          <primitive
            attach="normalMap"
            object={normalMap}
            wrapS={THREE.RepeatWrapping}
            wrapT={THREE.RepeatWrapping}
            repeat={[8, 4]}
          />
        </meshPhysicalMaterial>
        :
        <meshPhysicalMaterial
          envMap={envMap}
          metalness={1.0}
          roughness={0.0}
          clearcoat={0.9}
          reflectivity={1.0}
          needsUpdate={true}
        >
          <primitive
            attach="normalMap"
            object={normalMap}
            wrapS={THREE.RepeatWrapping}
            wrapT={THREE.RepeatWrapping}
            repeat={[8, 4]}
          />
        </meshPhysicalMaterial>
      }
    </mesh>
  )
}

const MirrorBall = (props) => {

  const { windowWidth, windowHeight } = props;

  return (
    <div
      className="mirrorball__wrapper"
      style={{
        width: Math.min(windowWidth, windowHeight) + 'px',
        height: Math.min(windowWidth, windowHeight) + 'px'
      }}
    >
      <Canvas>
        <directionalLight intensity={0.2} color={0xff0000} />
        <directionalLight intensity={0.2} color={0x00ff00} position={[0, -40, 400]} />
        <directionalLight intensity={0.2} color={0x0000ff} position={[0, 10, -200]} />
        <directionalLight intensity={0.3} color={0xffffff} position={[0, -20, 1200]} />
        <Suspense fallback={null}>
          <MirrorBallMesh {...props} />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default MirrorBall;