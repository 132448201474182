import { copyText } from '../../utils/misc';

const CopyTextElement = ({ text }) => {

  return (
    <div className="ui-item ui-item--copy-paste dj-booth__server-name__wrapper">
      <span className="dj-booth__server-name ui-item--copy-paste--to-copy">{text}</span>
      <button
        className="dj-booth__server-name__copy ui-item--copy-paste__button--copy"
        onClick={() => copyText(text)}
      >copy</button>
    </div>
  )
};

export default CopyTextElement;