import { useEffect, useState } from 'react';
import { fetchCockpitData, saveCollectionEntry } from '../../utils/cockpit';
import GenresFormInputSection from './GenresFormInputSection';
import TracklistFormInputSection from './TracklistFormInputSection';

const StreamingAndTracklistForm = (props) => {
  const { wheelsOfSteelInfo, myStreamPlaybackId } = props;
  const [tracklist, setTracklist] = useState([{ 'artist': '', 'track': '' }]);
  const [genres, setGenres] = useState(['']);
  const [currentArchiveItem, setCurrentArchiveItem] = useState({});

  useEffect(() => {
    const handleGetArchiveItems = (response) => {
      if (response.length > 0) {
        setCurrentArchiveItem(response[0]);
      }
    }
    fetchCockpitData('archive', handleGetArchiveItems, true, null, {playbackId: myStreamPlaybackId});
  }, [myStreamPlaybackId]);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    if (currentArchiveItem._id) {

      let trimmedTracklist = [...tracklist];
      if (trimmedTracklist[tracklist.length - 1].artist === '' && trimmedTracklist[tracklist.length - 1].track === '') {
        trimmedTracklist.pop();
      }

      let trimmedGenres = [...genres];
      if (trimmedGenres[genres.length - 1] === '') {
        trimmedGenres.pop();
      }

      const updatedArchiveItem = {
        ...currentArchiveItem,
        tracklist: {
          tracklist: [
            ...trimmedTracklist
          ]
        },
        genres: [...trimmedGenres]
      };

      setCurrentArchiveItem(updatedArchiveItem);

      saveCollectionEntry('archive',
        updatedArchiveItem,
        (e) => console.log('archive item saved'),
        (err) => console.log(err)
      );
    }
  }

  return (
    <div className="dj-booth__live__wrapper">
      <p>{wheelsOfSteelInfo.liveText}</p>
      <form onSubmit={handleSubmit}>
        <TracklistFormInputSection {...props} tracklist={tracklist} setTracklist={setTracklist} handleSubmit={handleSubmit} />
        <br />
        <GenresFormInputSection {...props} genres={genres} setGenres={setGenres} handleSubmit={handleSubmit} />
        <input type="submit" name="save" className="upload button button--margin-top" value="save" />
      </form>
    </div>
  );
}

export default StreamingAndTracklistForm;