import { useRef, useState, useEffect, Suspense } from 'react';
import { getImage } from '../utils/cockpit';
import { Canvas, useFrame } from '@react-three/fiber';
import { useTexture, useProgress } from '@react-three/drei';
import * as THREE from 'three';
import normalMapPath2 from '../../assets/normals2.png';
import CanvasRecorder from './CanvasRecorder';

const normalMaps = [
  normalMapPath2
];

const ShowAudioVisualMirrorBallEnvMap = (props) => {

  const { material, imageFullPath } = props;

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');

    // Create gradient
    var grd = ctx.createRadialGradient(100, 100, 0, 100, 100, 100);
    grd.addColorStop(0.0, '#888');
    grd.addColorStop(0.25, '#777');
    grd.addColorStop(0.5, '#888');
    grd.addColorStop(0.75, '#777');
    grd.addColorStop(1.0, '#888');

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 200, 200);

    // const dataUrl = canvas.toDataURL();
    new THREE.TextureLoader().load(
      imageFullPath,
      // dataUrl,
      (reflectionTexture) => {
        material.current.needsUpdate = true;
        material.current.envMap = reflectionTexture;
        material.current.envMap.anisotropy = 4;
        material.current.envMap.mapping = THREE.EquirectangularReflectionMapping;
        material.current.envMap.encoding = THREE.sRGBEncoding;
        material.current.envMap.minFilter = THREE.LinearFilter;
        material.current.envMap.magFilter = THREE.LinearFilter;
        //  = new THREE.Vector2(0.2, 0);
      }
    );

  }, [material, imageFullPath]);

  return null
}

const ShowAudioVisualMirrorBallScene = (props) => {

  const { imageFullPath, setIsLoading } = props;
  const { progress } = useProgress();
  const mesh = useRef();
  const group = useRef();
  const material = useRef();
  const normals = useTexture(normalMaps[Math.floor(Math.random() * normalMaps.length)], {
    anisotropy: 8
  });

  useFrame(({ mouse }) => {
    if (mesh.current) {
      mesh.current.rotation.y += 0.005;
    }
    if (progress === 100) {
      setIsLoading(false);
    }
  });

  return (
    <group ref={group}>
      <mesh
        ref={mesh}
        castShadow={true}
        receiveShadow={true}
      >
      <sphereBufferGeometry args={[2.4, 64, 64]} />
      <meshPhysicalMaterial
        metalness={1.0}
        roughness={0.0}
        clearcoat={0.9}
        reflectivity={1.0}
        ref={material}
      >
        <primitive
          attach="normalMap"
          object={normals}
          wrapS={THREE.RepeatWrapping}
          wrapT={THREE.RepeatWrapping}
          repeat={[8, 4]}
        />
          <ShowAudioVisualMirrorBallEnvMap material={material} imageFullPath={imageFullPath} />
        </meshPhysicalMaterial>
      </mesh>
    </group>
  )
}

const ShowAudioVisualMirrorBall = (props) => {

  const { item, imgDataUrl, isArtworkGenerator } = props;
  const { image } = item;
  const [imageFullPath, setImageFullPath] = useState('');
  const [imageDimensions, setImageDimensions] = useState({
    width: 1,
    height: 1
  });

  useEffect(() => {
    if (imgDataUrl && imgDataUrl !== '') {
      setImageFullPath(imgDataUrl);
    } else {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 200;
      canvas.height = 200;
      const grd = ctx.createRadialGradient(75, 50, 5, 90, 60, 100);
      grd.addColorStop(0, `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`);
      grd.addColorStop(0.25, `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`);
      grd.addColorStop(0.5, `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`);
      grd.addColorStop(1, `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`);
      ctx.fillStyle = grd;
      ctx.fillRect(0, 0, 200, 200);
      setImageFullPath(canvas.toDataURL());
    }
  }, [imgDataUrl]);

  useEffect(() => {
    let imgElement;

    const handleImgLoad = (e) => {
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;
      const baseWidth = 6;

      setImageDimensions({
        width: baseWidth,
        height: baseWidth / width * height
      });
      setImageFullPath(e.target.src);
    }

    const handleGetImage = (url) => {
      imgElement = document.createElement('img');
      imgElement.addEventListener('load', handleImgLoad);
      imgElement.src = url;
    }
    if (image && image.path) {
      getImage(image.path, 2048, 2048, 60, handleGetImage, 0);
    }

    return () => {
      if (imgElement) {
        imgElement.removeEventListener('load', handleImgLoad);
      }
    }
  }, [image]);

  return (
    <Canvas
      camera={{
        position: [5, 0, 0]
      }}
    >
      {
        isArtworkGenerator === true &&
        <CanvasRecorder {...props} imageFullPath={imageFullPath} />
      }
      <ambientLight intensity={0.2} />
      <pointLight color={'#fff'} intensity={0.3} position={[20, 3, 4]} /> */}
      <pointLight color={'#fff'} intensity={0.3} position={[-20, -3, -  4]} />
      <Suspense fallback={null}>
        {
          imageFullPath !== '' &&
          <ShowAudioVisualMirrorBallScene {...props} {...imageDimensions} imageFullPath={imageFullPath} />
        }
      </Suspense>
    </Canvas>
  )
}

export default ShowAudioVisualMirrorBall;
export { ShowAudioVisualMirrorBallEnvMap };