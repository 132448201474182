import { Link } from 'react-router-dom';
import getDateFromCMSDate from '../utils/date';
import LazyloadImage from './LazyloadImage';
import mirrorBallImage from '../../assets/android-icon-192x192.png';

const ArchiveItem = (props) => {

  const { item } = props;
  const { title, description, image, date } = item;

  return (
    <li className="archive__item col-xxs-12 col-xs-6 col-sm-4 col-lg-3">
      <Link
        to={`/show/${item._id}`}
        className="archive__item__inner"
      >
        <div className="archive__item__image__wrapper">
          {
            image && image.path ?
              <LazyloadImage image={image} width={640} isBackgroundImage={true} />
              :
              <img src={mirrorBallImage} className="archive__item__image--no-artwork" alt="mirrorball" width={640} />
          }
        </div>
        <div className="archive__item__info">
          <h4 className="archive__item__info__item archive__item__title">{title}</h4>
          {
            date && date !== '' &&
            <p className="archive__item__info__item archive__item__date">{getDateFromCMSDate(date).formattedDate}</p>
          }
          <p className="archive__item__description">{description}</p>
        </div>
      </Link>
    </li>
  )
}

export default ArchiveItem;