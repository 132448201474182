import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './js/App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter baseRoute="/">
      <Routes>
        <Route
          path={'*'}
          element={
            <App />
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
