import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import getDateFromCMSDate from '../utils/date';

const ShowInfo = (props) => {

  const { item } = props;
  const { title, date, description, genres, socialLink, socialLinkText, tracklist } = item;
  const [showInfoIsActive, setShowInfoIsActive] = useState(false);
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const linkCopiedTimeout = useRef();

  const copyLink = () => {
    setLinkIsCopied(true);
    if (linkCopiedTimeout.current) {
      clearTimeout(linkCopiedTimeout.current);
    }
    const newElement = document.createElement('input');
    newElement.setAttribute('type', 'text');
    newElement.value = window.location.href;

    /* Select the text field */
    newElement.select();
    newElement.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(newElement.value);
    setLinkIsCopied(true);

    linkCopiedTimeout.current = setTimeout(() => setLinkIsCopied(false), 3000);
  }

  return (
    <motion.div
      className="show__info"
    >
      <nav className="show__info__nav">
        <button
          className={`show__info__item ui__item button show__info__button--show-more-info${showInfoIsActive === true ? ' active' : ''}`}
          aria-label={`${showInfoIsActive === false ? 'Hide' : 'Show'} info about this show`}
          onClick={() => setShowInfoIsActive(!showInfoIsActive)}
        />
        <h3 className="show__info__item ui__item show__info__title">{title}</h3>
        {
          date && date !== '' &&
          <p className="show__info__item ui__item show__info__date">{getDateFromCMSDate(date).formattedDate}</p>
        }
        <button
          className={`show__info__item ui__item button show__info__button--copy-link${linkIsCopied === true ? ' active' : ''}`}
          aria-label={`Copy link to this show`}
          onClick={copyLink}
        >
          {
            linkIsCopied === true ?
          `✨`
          :
            `🔗`
          }
        </button>
        {
          socialLink &&
          <a
            href={socialLink}
            rel="noreferrer"
            target="_blank"
            className={`show__info__item ui__item button`}
            title={`Social link`}
            onClick={copyLink}
          >
            {socialLinkText ? socialLinkText : socialLink}
          </a>
        }
        {
          showInfoIsActive === true &&
          (description || genres) &&
          <div className="show__info__description show__info__item">
            <p className="show__info__description__text">{description}</p>
            <div className="show__info__description__genres">
              {
                genres && genres[0] &&
                genres.map(
                  (genre, i) => (
                    <p
                      className="show__info__item show__info__item__genre"
                      key={i}
                    >{genre}</p>
                  )
                )
              }
            </div>
          </div>
        }
        {
          showInfoIsActive === true && tracklist?.tracklist &&
          tracklist.tracklist[0] &&
          <div className="show__info__tracklist show__info__item">
            <h3 className="show__info__tracklist__heading">Tracklist 💿</h3>
            <ul className="show__info__tracklist__list">
                {
                  tracklist.tracklist.map(
                    (item, i) => (
                      <li className="show__info__tracklist__list__item">
                        <p className="show__info__tracklist__list__item__p show__info__tracklist__item__p--artist"><strong>{item.artist}</strong></p>
                        <p className="show__info__tracklist__list__item__p show__info__tracklist__item__p--track">{item.track}</p>
                      </li>
                  )
                )
              }
            </ul>
          </div>
        }
      </nav>
    </motion.div>
  )
}

export default ShowInfo;
