import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const Info = (props) => {

  const { windowWidth, windowHeight, setInfoIsActive, infoData } = props;

  return (
    <motion.div
      initial={{ x: '-100%' }}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      transition={{ style: 'ease' }}
      className="info__wrapper"
      style={{
        height: windowWidth < 768 ? windowHeight - 198 + 'px' : windowHeight - 228 + 'px'
      }}
    >
      <div className="info">
        <div className="info__inner">
          <button
            aria-label="close info"
            className="info__close"
            onClick={() => setInfoIsActive(false)}
            onTouchStart={() => setInfoIsActive(false)}
          />
          <div
            className="info__content"
            style={{
              maxHeight: windowWidth < 768 ? windowHeight - 198 + 'px' : windowHeight - 228 + 'px'
            }}
          >
            {
              infoData.info &&
              <div>{parse(infoData.info)}</div>
            }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Info;