import { useState } from 'react';
import { Link } from 'react-router-dom';
import { saveCollectionEntry } from '../../utils/cockpit';

const StartStreamForm = (props) => {

  const { windowWidth, streamName, setStreamName, startTime, setStartTime, endTime, setEndTime, wheelsOfSteelInfo } = props;
  const [isTwitch, setIsTwitch] = useState(false);
  const [stream, setStream] = useState('');
  const [streamIsSubmitted, setStreamIsSubmitted] = useState(false);
  const [streamSubmissionIsFailed, setStreamSubmissionIsFailed] = useState(false);
  const [endStreamPromptIsActive, setEndStreamPromptIsActive] = useState(false);
  const [streamHasFinished, setStreamHasFinished] = useState(false);
  const [streamPost, setStreamPost] = useState({});

  const handleChangeStreamName = (e) => {
    setStreamName(e.target.value);
  }

  const handleChangeStartTime = (e) => {
    setStartTime(e.target.value);
  }

  const handleChangeEndTime = (e) => {
    setEndTime(e.target.value);
  }

  const handleSuccessfulSubmission = (response) => {
    setStreamPost(response);
    setStreamIsSubmitted(true);
    setStreamSubmissionIsFailed(false);
  }

  const handleFailedSubmission = () => {
    setStreamSubmissionIsFailed(true);
  }

  const handleEndStream = (e) => {
    e.preventDefault();
    setEndStreamPromptIsActive(true);
  }

  const handleCompleteEndStream = (e) => {
    e.preventDefault();
    setEndStreamPromptIsActive(false);
    setStreamHasFinished(true);
    saveCollectionEntry(
      'room2',
      {
        ...streamPost,
        isLive: false
      }
    );
  }

  const startNewRoom2Stream = (e) => {
    e.preventDefault();
    if (stream && stream !== '') {
      const data = {
        title: streamName,
        startTime: startTime,
        endTime: endTime,
        isLive: true,
        isTwitch: isTwitch,
        twitchChannel: isTwitch === true ? stream : null,
        youtubeUrl: isTwitch === true ? null : stream
      };
      saveCollectionEntry('room2', data, handleSuccessfulSubmission, handleFailedSubmission);
    }
  }

  if (streamIsSubmitted === false) {
    return (
      <form
        className="dj-booth__stream-form"
        onSubmit={startNewRoom2Stream}
      >
        {
          streamSubmissionIsFailed === true &&
          <p>Something went wrong! Try again babes</p>
        }
        <fieldset className={`dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--block`}>
          <label
            className="dj-booth__stream-form__label"
            htmlFor="stream name"
          >Please enter the name of your show</label>
          <input
            className="dj-booth__stream-form__input--name"
            type="text"
            name="stream name"
            value={streamName}
            onChange={handleChangeStreamName}
            placeholder="name"
          />
          <p>Emojis are strongly encouraged. If you want to go all out with some cute unicode fonts, <a
            rel="noreferrer"
            target="_blank"
            className="link"
            href="https://qaz.wtf/u/convert.cgi?text=your+show+name"
          >click here</a>.</p>
        </fieldset>
        <fieldset className={`dj-booth__stream-form__fieldset`}>
          <label
            className="dj-booth__stream-form__label label--hidden"
            style={{
              display: 'none'
            }}
            htmlFor="is Twitch"
          >Twitch Stream?</label>
          <p style={{
            marginRight: '12px',
            transition: 'opacity 0.2s ease',
            opacity: isTwitch === true ? 0.3 : 1
          }}>YouTube</p>
          <input
            className="dj-booth__stream-form__input--is-twitch"
            type="checkbox"
            name="is Twitch"
            value={isTwitch}
            onChange={() => setIsTwitch(!isTwitch)}
            placeholder="name"
          />
          <p
            style={{
              transition: 'opacity 0.2s ease',
              opacity: isTwitch === true ? 1 : 0.3
            }}
          >Twitch</p>
        </fieldset>
        <fieldset className={`dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--block`}>
          <label
            className="dj-booth__stream-form__label"
            htmlFor="stream"
          >{isTwitch === true ? 'Twitch channel name' : 'YouTube URL'}</label>
          <input
            className="ui-item"
            type={isTwitch === true ? 'text' : 'url'}
            name="stream"
            placeholder={isTwitch === true ? 'Twitch channel name' : 'YouTube URL'}
            value={stream}
            onChange={(e) => setStream(e.target.value)}
          />
        </fieldset>
        <fieldset className={`dj-booth__stream-form__fieldset${windowWidth < 500 ? ' dj-booth__stream-form__fieldset--block' : ''}`}>
          <label
            className="dj-booth__stream-form__label"
            htmlFor="stream name"
          >Start time</label>
          <input
            className="dj-booth__stream-form__input--name"
            type="time"
            name="stream name"
            value={startTime}
            onChange={handleChangeStartTime}
            placeholder="name"
          />
          <label
            className="dj-booth__stream-form__label"
            htmlFor="stream name"
          >End time</label>
          <input
            className="dj-booth__stream-form__input--name"
            type="time"
            name="stream name"
            value={endTime}
            onChange={handleChangeEndTime}
            placeholder="name"
          />
        </fieldset>
        {
          streamName !== '' && startTime !== '' && endTime !== '' &&
          <fieldset className="dj-booth__stream-form__fieldset">
            <input
              className="button"
              type="submit"
              value="Start streaming"
            />
          </fieldset>
        }
      </form>
    );
  } else if (streamHasFinished === false) {
    if (endStreamPromptIsActive === false) {
      return (
        <form
          className="dj-booth__stream-form"
          onSubmit={handleEndStream}
        >
          <p>You’re live in Room 2!</p>
          <input
            className="button"
            type="submit"
            value="Stop streaming"
          />
        </form>
      );
    } else {
      return (
        <div className="dj-booth__stream-form">
          <p>Are you sure you want to end the stream now?</p>
          <button
            className="button"
            onClick={handleCompleteEndStream}
            style={{ marginRight: '12px', marginBottom: '12px' }}
          >Continue</button>
          <button
            className="button"
            onClick={() => setEndStreamPromptIsActive(false)}
          >Wait, go back!</button>
        </div>
      );
    }
  } else {
    return (
      <div className="dj-booth__stream-form">
        <p>{wheelsOfSteelInfo.finishedText}</p>
        <p><Link to="/submit" className="link">imissmymates.club/upload</Link></p>
      </div>
    );
  }
}

export default StartStreamForm;