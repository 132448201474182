import { useState } from 'react';
import { TwitchEmbed } from "react-twitch-embed"


const TwitchPlayer = (props) => {

  const { noControls, twitchChannel } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [aspectRatio] = useState(0.5625);

  const handleInteract = (e) => {
    if (noControls === true && e._reactName === 'onClick') {
      setIsPlaying(!isPlaying);
    }
  }

  if (twitchChannel !== '') {
    return (
      <div
        onClick={handleInteract}
        onTouchStart={handleInteract}
        onMouseMove={handleInteract}
        style={{
          paddingBottom: 100 * aspectRatio + '%'
        }}
      >
        <div className="video__wrapper">
          <TwitchEmbed
            channel={twitchChannel}
            withChat={false}
            width={'100%'}
            height={'100%'}
          />
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default TwitchPlayer;