import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchCockpitData } from '../utils/cockpit';
import { copyText } from '../utils/misc';
import Room2Booth from '../components/Room2Booth/Room2StreamForm';
import Password from '../components/WheelsOfSteel/Password';
import parse from 'html-react-parser';

const CopyTextElement = ({ text }) => {

  return (
    <div className="ui-item ui-item--copy-paste dj-booth__server-name__wrapper">
      <span className="dj-booth__server-name ui-item--copy-paste--to-copy">{text}</span>
      <button
        className="dj-booth__server-name__copy ui-item--copy-paste__button--copy"
        onClick={() => copyText(text)}
      >copy</button>
    </div>
  )
}

const WheelsOfSteelContent = (props) => {

  const { windowHeight, activeLivestream, streamKey, passwordIsCorrect, wheelsOfSteelInfo, youAreStreaming, youAreConnecting, youHaveFinishedStreaming } = props;

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB ROOM 2 BOOTH';
  }, []);

  return (
    <motion.div
      className="dj-booth"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      {
        passwordIsCorrect === true ?
          <div className="dj-booth__content">
            {
              !activeLivestream.playbackId && youAreStreaming === false && youAreConnecting === false && youHaveFinishedStreaming === false &&
              <div className="dj-booth__livestream">
                {
                  typeof streamKey === 'undefined' || streamKey === '' ?
                  <Room2Booth {...props} />
                  :
                  <div className="stream-key__wrapper">
                    <h3>Server name</h3>
                    <CopyTextElement text={`rtmp://global-live.mux.com:5222/app/`} />
                    <h4>Stream key is</h4>
                    <CopyTextElement text={streamKey} />
                    {
                      wheelsOfSteelInfo.streamingKeyInfo &&
                      <div>{parse(wheelsOfSteelInfo.streamingKeyInfo)}</div>
                    }
                    {
                      wheelsOfSteelInfo.welcomeText &&
                      <div>
                        {parse(wheelsOfSteelInfo.welcomeText)}
                      </div>
                    }
                  </div>
                }
              </div>
            }
            {
              youAreConnecting === true &&
              <p>Patience doll, your stream is connecting</p>
            }
            {
              youAreStreaming === true &&
              <div className="dj-booth__live__wrapper">
                <p>{wheelsOfSteelInfo.liveText}</p>
              </div>
            }
            {
              youHaveFinishedStreaming === true &&
              <div className="dj-booth__finished__wrapper">
                  <p>{wheelsOfSteelInfo.finishedText}</p>
                  <p><Link to="/submit" className="link">imissmymates.club/submit</Link></p>
              </div>
            }
            {
              activeLivestream.playbackId && youAreStreaming === false &&
              <div className="dj-booth__other-show-in-progress__wrapper">
                <p>{wheelsOfSteelInfo.otherShowInProgressText}</p>
              </div>
            }
          </div>
          :
          <Password {...props} />
      }
    </motion.div>
  )
}

const WheelsOfSteel = (props) => {

  const [streamName, setStreamName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
  const [wheelsOfSteelInfo, setwheelsOfSteelInfo] = useState({});

  useEffect(() => {
    if (passwordIsCorrect === true) {
      fetchCockpitData('wheelsOfSteelInfo', setwheelsOfSteelInfo, false);
    }
  }, [passwordIsCorrect]);

  return (
    <WheelsOfSteelContent
      {...props}
      streamName={streamName}
      setStreamName={setStreamName}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      passwordIsCorrect={passwordIsCorrect}
      setPasswordIsCorrect={setPasswordIsCorrect}
      wheelsOfSteelInfo={wheelsOfSteelInfo}
    />
  )
}

export default WheelsOfSteel;