import parse from 'html-react-parser';

const StreamingInstructions = (props) => {

  const { greenRoom } = props;

  return (
    <div className="streaming-instructions__wrapper">
      {greenRoom.streamingInstructions && <div>{parse(greenRoom.streamingInstructions)}</div>}
    </div>
  )
}

export default StreamingInstructions;