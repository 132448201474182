import { motion } from 'framer-motion';

const SmokingArea = (props) => {

  const { windowHeight, windowWidth } = props;

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ style: 'ease' }}
      style={{
        height: windowHeight + 'px'
      }}
      className="smoking-area__wrapper"
    >
      <iframe
        src={`https://i-miss-my-mates-chatango.netlify.app/`}
        title="chatango"
        frameBorder="0"
        width="100%" height="100%"
        style={{
          height: windowWidth >= 900 ? windowHeight - 60 + 'px' : windowHeight - 90 + 'px',
          marginTop: windowWidth >= 900 ? 0 : 30 + 'px',
        }}
      />
    </motion.div>
  )
}

export default SmokingArea;