import { cockpitUrl } from '../utils/cockpit';
import Artwork from '../components/Artwork';

const ShowArtwork = (props) => {

  const { item } = props;
  const { image, title, date, startTime, endTime } = item;

  return (
    <div className="page page--artwork">
      <Artwork
        {...props}
        image={cockpitUrl + image.path}
        title={title}
        date={date}
        startTime={startTime}
        endTime={endTime}
        withButton={true}
        withMessage={true}
      />
    </div>
  )
}

export default ShowArtwork;