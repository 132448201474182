import { useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Password = (props) => {

  const { setPasswordIsCorrect, socket, passwordProtectedText } = props;

  const [password, setPassword] = useState('');
  const [wrongPasswordEntered, setWrongPasswordEntered] = useState(0);

  const handleEnterPassword = (e) => {
    e.preventDefault();
    socket.emit('submit password', JSON.stringify({ password: password }));

    const handlePasswordCorrect = () => {
      setPasswordIsCorrect(true);
      socket.removeEventListener('password correct', handlePasswordCorrect);
      console.log('password is correct');
    }
    socket.addEventListener('password correct', handlePasswordCorrect);

    const handlePasswordIncorrect = () => {
      if (wrongPasswordEntered + 1 === 3) {
        props.navigate('/');
      }
      setWrongPasswordEntered(wrongPasswordEntered + 1);
      socket.removeEventListener('password incorrect', handlePasswordIncorrect);
      console.log('password is incorrect');
    }
    socket.addEventListener('password incorrect', handlePasswordIncorrect);
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  }

  return (
    <div className="dj-booth__content">
      <form
        className="dj-booth__stream-form"
        onSubmit={handleEnterPassword}
      >
        {
          passwordProtectedText.WheelsOfSteel &&
          <div className="dj-booth__stream-form__text">{parse(passwordProtectedText.WheelsOfSteel)}</div>
        }
        <fieldset className="dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--block">
          <label
            className="dj-booth__stream-form__label"
            htmlFor="password"
          >Please enter your password 💋</label>
          <input
            name="password"
            type="password"
            placeholder="password"
            className="dj-booth__stream-form__input--name"
            value={password}
            onChange={handleChangePassword}
          />
        </fieldset>
        <fieldset className="dj-booth__stream-form__fieldset">
          <input type="submit" value="submit" className="button" />
          <Link to="/" className="button">do you take requests???</Link>
        </fieldset>
      </form>
    </div>
  )
}

export default Password;