const TracklistFormInputSection = (props) => {

  const { tracklist, setTracklist, handleSubmit } = props;

  const addNewTrack = (i) => {
    if (i === tracklist.length - 1) {
      const newTracklist = [...tracklist];
      newTracklist.push({ track: '', artist: '' });
      setTracklist(newTracklist);
      handleSubmit && handleSubmit();
    }
  }

  return (
    <div className="dj-booth__stream-form__fieldset--block">
      <h3>Tracklist</h3>
      <p style={{ opacity: 0.5, margin: 0 }}>Please upload this, I need it for licencing!!!</p>
      {
        tracklist[0] && tracklist.map(
          (item, i) => (
            <fieldset
              className="dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--wrap"
              key={i}
            >
              <label htmlFor={`artist name ${i}`} className="label--hidden">Artist name</label>
              <input
                type="text"
                className="artwork-generator__form__input"
                value={tracklist[i].artist}
                name={`artist name ${i}`}
                placeholder="artist name"
                onChange={(e) => {
                  const newTracklist = [...tracklist];
                  newTracklist[i].artist = e.target.value;
                  setTracklist(newTracklist);
                  addNewTrack(i);
                }}
              />
              <label htmlFor={`track name ${i}`} className="label--hidden">Track title</label>
              <input
                type="text"
                className="artwork-generator__form__input"
                value={tracklist[i].track}
                name={`track name ${i}`}
                placeholder="track title"
                onChange={(e) => {
                  const newTracklist = [...tracklist];
                  newTracklist[i].track = e.target.value;
                  setTracklist(newTracklist);
                  addNewTrack(i);
                }}
              />
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  const newTracklist = [...tracklist];
                  newTracklist.splice(i, 1);
                  setTracklist(newTracklist);
                }}
              >remove</button>
            </fieldset>
          )
        )
      }
      <button
        className="button button--margin-top"
        onClick={(e) => {
          e.preventDefault();
          const newTracklist = [...tracklist];
          newTracklist.push({ track: '', artist: '' });
          setTracklist(newTracklist);
          handleSubmit && handleSubmit();
        }}
      >Add track +</button>
    </div>
  )
}

export default TracklistFormInputSection;