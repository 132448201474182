const createRandomString = (strlength) => {
  let randomString = '';
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < strlength ? strlength : 16; i++) {
    randomString += chars[Math.floor(Math.random() * chars.length)];
  }

  return randomString;
}

const copyText = (text, callback) => {
  var copyText = document.createElement('input');
  copyText.setAttribute('type', 'text');
  copyText.value = text;

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

   /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.value);

  callback && callback();
}

export { createRandomString, copyText };