import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, isBackgroundImage, desaturate, callback, className, backgroundPositionX, backgroundPositionY } = props;
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    let isMounted = true;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }
    if (image.path && image.path !== '') {
      const path = image.path;

      getImage(
        path,
        w ? w : 2048,
        h ? h : 2048,
        100,
        (imageUrl) => {
          const url = imageUrl;
          if (isMounted === true) {
            setImageSrcLarge(url);
            img = document.createElement('img');
            img.addEventListener('load', onImageLoad);
            img.src = url;
          }
        },
        false
      );
    }

    return () => {
      isMounted = false;
      if (img) {
        img.removeEventListener('load', onImageLoad);
        img = null;
      }
    }
  }, [image.path, w, h, desaturate]);

  useEffect(() => {
    if (imageIsLoaded === true) {
      callback && callback();
    }
  }, [imageIsLoaded, callback]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${className ? ` ` + className : ''}`}
          style={{
            backgroundImage: imageIsLoaded === true ? `url(${imageSrcLarge})` : null,

            backgroundPositionX: backgroundPositionX ? backgroundPositionX + '%' : 50 + '%',
            backgroundPositionY: backgroundPositionY ? backgroundPositionY + '%' : 50 + '%'
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${className ? ` ` + className : ''}`}
          src={imageIsLoaded === true ? imageSrcLarge : null}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;