const GenresFormInputSection = (props) => {

  const { genres, setGenres, handleSubmit } = props;

  return (
    <div className="dj-booth__stream-form__fieldset--block">
      <h3>Genres</h3>
      {
        genres.length > 0 && genres.map(
          (item, i) => (
            <fieldset
              className="dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--wrap"
              key={i}
            >
              <label htmlFor={`genre ${i}`} className="label--hidden">Genre</label>
              <input
                type="text"
                className="artwork-generator__form__input"
                value={genres[i]}
                name={`genre ${i}`}
                placeholder="genre"
                onChange={(e) => {
                  const newGenres = [...genres];
                  newGenres[i] = e.target.value;
                  if (i === genres.length - 1) {
                    newGenres.push('');
                    handleSubmit && handleSubmit();
                  }
                  setGenres(newGenres);
                }}
              />
              <button
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  const newGenres = [...genres];
                  newGenres.splice(i, 1);
                  setGenres(newGenres);
                  handleSubmit && handleSubmit();
                }}
              >remove</button>
            </fieldset>
          )
        )
      }
    </div>
  )
}

export default GenresFormInputSection;