import { useState } from 'react';
import parse from 'html-react-parser';
import Artwork from '../Artwork';

const ArtworkGenerator = (props) => {

  const { greenRoom } = props;
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isDiscoBallVisualiser, setIsDiscoBallVisualiser] = useState(true);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleUploadImage = (e) => {
    if (e.target?.files[0]) {
      const file = e.target.files[0];
      getBase64(file).then(
        data => {
          setImage(data);
        }
      );
    }
  }

  return (
    <div className="artwork-generator__wrapper">
      <Artwork
        {...props}
        image={image}
        title={title}
        date={date}
        startTime={startTime}
        endTime={endTime}
        isDiscoBallVisualiser={isDiscoBallVisualiser}
      />
      <form
        className="artwork-generator__form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {
          greenRoom.artworkGeneratorText &&
          <div>{parse(greenRoom.artworkGeneratorText)}</div>
        }
        <label
          htmlFor="image"
          className="artwork-generator__form__label"
        >Add your show’s image</label>
        <input
          className="artwork-generator__form__input"
          type="file"
          name="image"
          onChange={handleUploadImage}
          accept="image/*"
        />
        <label
          htmlFor="image"
          className="artwork-generator__form__label"
        >The name of your show</label>
        <input
          className="artwork-generator__form__input"
          name="title"
          type="text"
          placeholder="title"
          onChange={(e) => { setTitle(e.target.value) }}
          value={title}
        />
        <label
          htmlFor="image"
          className="artwork-generator__form__label"
        >The date of your show</label>
        <input
          className="artwork-generator__form__input"
          name="date"
          type="date"
          onChange={(e) => { setDate(e.target.value) }}
          value={date}
        />
        <label
          htmlFor="image"
          className="artwork-generator__form__label"
        >The start time of your show</label>
        <input
          className="artwork-generator__form__input"
          name="start time"
          type="time"
          placeholder="startTime"
          onChange={(e) => { setStartTime(e.target.value) }}
          value={startTime}
        />
        <label
          htmlFor="image"
          className="artwork-generator__form__label"
        >The end time of your show</label>
        <input
          className="artwork-generator__form__input"
          name="end time"
          type="time"
          placeholder="endTime"
          onChange={(e) => { setEndTime(e.target.value) }}
          value={endTime}
        />
        {
          image !== '' &&
          <input
            className="button"
            type="submit"
            value="Change visuals"
            onClick={() => setIsDiscoBallVisualiser(!isDiscoBallVisualiser)}
          />
        }
      </form>
    </div>
  )
}

export default ArtworkGenerator;