import { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';
import Marquee from 'react-marquee-slider';
import times from "lodash/times";

const Listen = (props) => {

  const { activeLivestream, isHidden } = props;
  const { title, startTime, endTime, playbackId } = activeLivestream;
  const [src, setSrc] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const audio = useRef();

  const handleClickListenButton = () => {
    if (audio.current) {
      if (isPlaying !== true) {
        audio.current.play()
        .then(() => {
          setIsPlaying(true);
          console.log('playing')
        })
        .catch(error => {
          console.log(error);
          setIsPlaying(false);
        });
      } else {
        audio.current.pause();
        setIsPlaying(false);
      }
    }
  }

  useEffect(() => {
    let hls;
    let theSrc = '';

    if (activeLivestream) {
      if (activeLivestream.playbackId) {
        theSrc = `https://stream.mux.com/${activeLivestream.playbackId}.m3u8`;
        setSrc(theSrc);
      }
    }
    if (audio.current && theSrc !== '') {
      const video = audio.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = theSrc;
        console.log('Native HLS support')
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        console.log('HLS is supported');
        hls = new Hls();
        hls.loadSource(theSrc);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src, activeLivestream]);

  return (
    <div className={`listen__wrapper${activeLivestream.playbackId ? ' active' : ''}`}>
      {
        activeLivestream.playbackId && isHidden !== true &&
        <button
          className={`listen__button${isPlaying === true ? ' active' : ''}`}
          onClick={handleClickListenButton}
        />
      }
      <div
        className="header__marquee__wrapper"
      >
        <Marquee
          velocity={12}
          direction={'rtl'}
        >
          {
            12 &&
            times(12, Number).map(
              i => (
                <div
                  className="work__item__inner"
                  key={i}
                >
                  {
                    playbackId && title && startTime && endTime && isHidden !== true ?
                      <p className="announcement">{title} {startTime}—{endTime}</p>
                      :
                      <p className="announcement">imissmymates.club is currently off air 💤 see you soon babs</p>
                  }
                </div>
              )
            )
          }
        </Marquee>
      </div>
      {
        src !== '' &&
        <audio ref={audio} preload='none' />
      }
    </div>
  )
}

export default Listen;