import { Link } from "react-router-dom";
import AnnouncementMarquee from './AnnouncementMarquee';

const UI = (props) => {

  const { location, smokingAreaIsActive, setSmokingAreaIsActive, announcementText, infoIsActive, setInfoIsActive, archiveData, room2 } = props;

  return (
    <header className="header">
      {
        announcementText !== '' &&
        <div className="announcement__wrapper header__item">
          <AnnouncementMarquee {...props} />
        </div>
      }
      <nav className="header__menu">
        <div className="header__menu__inner">
          <div className="header__item">
            <Link
              className={`button${location.pathname === '/' ? ' active' : ''}`}
              to={'/'}
            >Main Room</Link>
          </div>
          {
            room2.isLive === true &&
            <div
              className="header__item"
            >
              <Link
                className={`button${location.pathname === '/room-2' ? ' active' : ''}`}
                to={'/room-2'}
              >Room 2</Link>
            </div>
          }
          {
            archiveData[0] &&
            <div
              key="archiveButton"
              className="header__item"
            >
              <Link
                className={`button${location.pathname === '/archive' ? ' active' : ''}`}
                to={'/archive'}
              >Archive</Link>
            </div>
          }
          <button
            className={`button button--smoking-area header__item${smokingAreaIsActive === true ? ' active' : ''}`}
            onClick={() => setSmokingAreaIsActive(!smokingAreaIsActive)}
            aria-label={`${smokingAreaIsActive === false ? 'Open' : 'Close'} the chat room`}
          >
            {
              smokingAreaIsActive === false ?
                'Head to the smoking area'
                :
                'Front left by the speaker'
            }
          </button>
          <a
            className={`button link--instagram header__item`}
            rel="noreferrer"
            href="https://instagram.com/imissmymates.club/"
            target="_blank"
          >@imissmymates.club</a>
          <button
            className={`button button--info header__item${infoIsActive === true ? ' active' : ''}`}
            onClick={() => setInfoIsActive(!infoIsActive)}
            aria-label={`${infoIsActive === false ? 'Show' : 'Hide'} information`}
          >{infoIsActive === false ? `I’m lost` : `Hide info`}</button>
          <Link
            className={`button header__item${location.pathname === '/apply' ? ' active' : ''}`}
            to={'/apply'}
          >Want to play a show?</Link>
          <Link
            className={`button header__item${location.pathname === '/wheels-of-steel' ? ' active' : ''}`}
            to={'/wheels-of-steel'}
          >Wheels of Steel</Link>
          <Link
            className={`button header__item${location.pathname === '/green-room' ? ' active' : ''}`}
            to={'/green-room'}
          >Green Room</Link>
        </div>
      </nav>
    </header>
  )
}

export default UI;