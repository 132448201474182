import { Fragment, useState, useEffect } from 'react';
import ShowAudioVisualSplat from './ShowAudioVisualSplat';
import ShowAudioVisualMirrorBall from './ShowAudioVisualMirrorBall';
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import getDateFromCMSDate from '../utils/date';

const ArtworkEmoji = (props) => {

  return (
    <span> ♥︎ </span>
  )
}

const ArtworkMarquee = (props) => {

  const { title, date, startTime, endTime, windowWidth, windowHeight } = props;

  return (
    <div
      className="artwork__marquee__wrapper ui-item"
      style={{
        height: windowWidth < 540 || windowHeight < 540 ?
          2.4 / 540 * Math.min(windowWidth, windowHeight) + 'rem' : null,
        width: windowWidth < 540 || windowHeight < 540 ? `calc(100% - ${23 / 540 * Math.min(windowWidth, windowHeight)}px)` : null,
        left: windowWidth < 540 || windowHeight < 540 ? `${12 / 540 * Math.min(windowWidth, windowHeight)}px` : null,
        bottom: windowWidth < 540 || windowHeight < 540 ? `${12 / 540 * Math.min(windowWidth, windowHeight)}px` : null,
        borderRadius: windowWidth < 540 || windowHeight < 540 ?
          1.2 / 540 * Math.min(windowWidth, windowHeight) + 'rem' : null,
        fontSize: windowWidth < 540 || windowHeight < 540 ?
          1 / 540 * Math.min(windowWidth, windowHeight) + 'rem' : null
      }}
    >
      <Marquee
        velocity={2}
        direction={'rtl'}
      >
        {
          12 &&
          times(12, Number).map(
            i => (
              <div
                className="work__item__inner"
                key={i}
              >
                <p className="announcement">
                  <ArtworkEmoji />
                  {title}
                  {
                    title !== '' &&
                    <ArtworkEmoji />
                  }
                  {date !== '' && getDateFromCMSDate(date).formattedDate}
                  {
                    date !== '' &&
                    <ArtworkEmoji />
                  }
                  {startTime !== '' && endTime !== '' && `${startTime}–${endTime}`}
                  {
                    startTime !== '' && endTime !== '' &&
                    <ArtworkEmoji />
                  }
                  listen online at imissmymates.club
                  <ArtworkEmoji />
                  @imissmymates.club
                  <ArtworkEmoji />
                </p>
              </div>
            )
          )
        }
      </Marquee>
    </div>
  )
}

const Artwork = (props) => {

  const { windowWidth, windowHeight, image, title, date, startTime, endTime, isDiscoBallVisualiser, withMessage, withButton } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isTheDiscoBallVisualiser, setIsTheDiscoBallVisualiser] = useState(typeof isDiscoBallVisualiser !== 'undefined' ? isDiscoBallVisualiser : true);

  useEffect(() => {
    if (typeof isDiscoBallVisualiser !== 'undefined') {
      setIsTheDiscoBallVisualiser(isDiscoBallVisualiser);
    }
  }, [isDiscoBallVisualiser]);

  if (image !== '') {
    return (
      <Fragment>
      <div
        className="artwork__outer"
      >
        <div
          className="artwork__wrapper"
          style={{
            backgroundImage: `url(${image})`,
            borderRadius: windowWidth < 540 || windowHeight < 540 ?
              1.2 / 540 * Math.min(windowWidth, windowHeight) + 'rem' : null,
            width: windowWidth < 540 || windowHeight < 540 ?
              `calc(100% - ${24 / 540 * Math.min(windowWidth, windowHeight)}px)` : null,
            height: windowWidth < 540 || windowHeight < 540 ?
              `calc(100% - ${36 / 540 * Math.min(windowWidth, windowHeight)}px - ${2.4 / 540 * Math.min(windowWidth, windowHeight)}rem)` : null,
            left: windowWidth < 540 || windowHeight < 540 ? `${12 / 540 * Math.min(windowWidth, windowHeight)}px` : null,
            top: windowWidth < 540 || windowHeight < 540 ? `${12 / 540 * Math.min(windowWidth, windowHeight)}px` : null
          }}
        >
          {
            isTheDiscoBallVisualiser === true ?
              <ShowAudioVisualMirrorBall
                imgDataUrl={image}
                item={{}}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              :
              <ShowAudioVisualSplat
                imgDataUrl={image}
                item={{}}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
          }
        </div>
        <ArtworkMarquee title={title} date={date} startTime={startTime} endTime={endTime} {...props} />
        </div>
        {
          withMessage === true &&
          <p>Screen record this artwork if you’d like to share it to social media ❤️‍🔥</p>
        }
        {
          withButton === true && typeof isDiscoBallVisualiser === 'undefined' &&
          <button
            className="button"
            onClick={() => setIsTheDiscoBallVisualiser(!isTheDiscoBallVisualiser)}
          >
            Change visuals
          </button>
        }
      </Fragment>
    );
  } else {
    return null;
  }
}

export default Artwork;