import { useEffect } from 'react';
import { motion } from 'framer-motion';
import ArchiveItem from '../components/ArchiveItem';
import HelmetContent from '../utils/HelmetContent';

const Archive = (props) => {

  const { windowHeight, archiveData } = props;

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB ARCHIVE';
  }, []);

  return (
    <motion.div
      className="archive container-fluid no-padding"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={`Archive`}
      />
      <ul className="archive__list row">
        {
          archiveData &&
          archiveData[0] &&
          archiveData.map(
            (item, i) => (
              item.published === true &&
              <ArchiveItem {...props} item={item} key={i} />
            )
          )
        }
      </ul>
    </motion.div>
  )
}

export default Archive;