import { useState } from 'react';
import { uploadAsset } from '../../utils/cockpit';
import Artwork from '../Artwork';
import WheelsOfSteelImageUploader from './WheelsOfSteelImageUploader';

const StartStreamForm = (props) => {

  const { windowWidth, streamName, setStreamName, startTime, setStartTime, endTime, setEndTime, descriptionValue, setDescriptionValue, socket, setArchiveItem, imagePreviewPath, setImagePreviewPath } = props;
  const [imagePath, setImagePath] = useState('');
  const [imageValue, setImageValue] = useState({});
  const [instagramHandle, setInstagramHandle] = useState('');

  const handleChangeStreamName = (e) => {
    setStreamName(e.target.value);
  }

  const handleChangeStartTime = (e) => {
    setStartTime(e.target.value);
  }

  const handleChangeEndTime = (e) => {
    setEndTime(e.target.value);
  }

  const startNewMuxStream = (image) => {
    socket.emit('create mux stream', {
      streamName: streamName,
      startTime: startTime,
      endTime: endTime,
      date: new Date().toISOString(),
      imagePath: '/storage/uploads' + image.path,
      description: descriptionValue,
      socialLink: instagramHandle !== '' ? `https://instagram.com/${instagramHandle.replace('@','')}/` : null,
      socialLinkText: instagramHandle !== '' ? `@${instagramHandle.replace('@', '')}` : null,
      type: "Audio"
    });
    setArchiveItem({
      streamName: streamName,
      startTime: startTime,
      endTime: endTime,
      date: new Date().toISOString(),
      imagePath: imagePath
    });
  }

  const createPost = (image) => {
    if (image[0]) {
      setImagePath('/storage/uploads' + image[0].path);
      startNewMuxStream(image[0]);
    }
  }
  
  const handleSubmit = (e) => {
    e && e.preventDefault();
    uploadAsset(imageValue, createPost, (error) => {
      console.log(error);
    });
  }

  return (
    <form
      className="dj-booth__stream-form"
      onSubmit={handleSubmit}
    >
      <fieldset className={`dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--block`}>
        <label
          className="dj-booth__stream-form__label"
          htmlFor="stream name"
        >Please enter the name of your show</label>
        <input
          className="dj-booth__stream-form__input--name"
          type="text"
          required={true}
          name="stream name"
          value={streamName}
          onChange={handleChangeStreamName}
          placeholder="name"
        />
        <p>Emojis are strongly encouraged. If you want to go all out with some cute unicode fonts, <a
          rel="noreferrer"
          target="_blank"
          className="link"
          href="https://qaz.wtf/u/convert.cgi?text=your+show+name"
        >click here</a>.</p>
        <p>(Emojis and unicode characters don’t work with MixCloud very well, so if you want your title to show up on your MixCloud embed probably avoid these)</p>
      </fieldset>
      <fieldset className="dj-booth__stream-form__fieldset dj-booth__stream-form__fieldset--block dj-booth__stream-form__fieldset--image">
        <div>
          {
            imagePreviewPath !== '' &&
            <Artwork
              {...props}
              image={imagePreviewPath}
              title={streamName}
              date={new Date().toISOString()}
              startTime={startTime ? startTime : ''}
              endTime={endTime ? endTime : ''}
              withButton={true}
              withMessage={true}
            />
          }
          <label className="artwork-generator__form__label" htmlFor="image">Add your show’s image</label>
          <WheelsOfSteelImageUploader
            {...props}
            imageValue={imageValue}
            setImageValue={setImageValue}  
            imagePreviewPath={imagePreviewPath}
            setImagePreviewPath={setImagePreviewPath}
          />
        </div>
      </fieldset>
      <fieldset className={`dj-booth__stream-form__fieldset${windowWidth < 500 ? ' dj-booth__stream-form__fieldset--block' : ''}`}>
        <label
          className="dj-booth__stream-form__label"
          htmlFor="stream name"
        >Start time</label>
        <input
          className="dj-booth__stream-form__input--name"
          type="time"
          required={true}
          name="stream name"
          value={startTime}
          onChange={handleChangeStartTime}
          placeholder="name"
        />
        <label
          className="dj-booth__stream-form__label"
          htmlFor="stream name"
        >End time</label>
        <input
          className="dj-booth__stream-form__input--name"
          type="time"
          required={true}
          name="stream name"
          value={endTime}
          onChange={handleChangeEndTime}
          placeholder="name"
        />
      </fieldset>
      <fieldset className={`dj-booth__stream-form__fieldset${windowWidth < 500 ? ' dj-booth__stream-form__fieldset--block' : ''}`}>
        <label
          className="dj-booth__stream-form__label"
          htmlFor="description"
        >Description</label>
        <textarea
          name="description"
          required={true}
          className="dj-booth__stream-form__textarea"
          value={descriptionValue}
          onChange={(event) => {
            setDescriptionValue(event.target.value);
          }}
        />
      </fieldset>
      <fieldset className={`dj-booth__stream-form__fieldset${windowWidth < 500 ? ' dj-booth__stream-form__fieldset--block' : ''}`}>
        <label
          className="dj-booth__stream-form__label"
          htmlFor="description"
        >Instagram Handle</label>
        <div className="dj-booth__stream-form__input--instagram__wrapper">
          @
          <input
            className="dj-booth__stream-form__input--name dj-booth__stream-form__input--instagram"
            type="text"
            name="stream name"
            value={instagramHandle}
            onChange={(event) => setInstagramHandle(event.target.value)}
            placeholder="your_instagram_handle"
          />
        </div>
      </fieldset>
      {
        streamName !== '' && startTime !== '' && endTime !== '' &&
        <fieldset className="dj-booth__stream-form__fieldset">
          <input
            className="button"
            type="submit"
            value="Start streaming"
          />
        </fieldset>
      }
    </form>
  )
}

export default StartStreamForm;