import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';
import { fetchCockpitData } from './utils/cockpit';
import { io } from 'socket.io-client';
import { ENDPOINT } from './utils/Socket';
import Content from './components/Content';

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [isLoading, setIsLoading] = useState(true);
  const [smokingAreaIsActive, setSmokingAreaIsActive] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [djApplicationPage, setDjApplicationPage] = useState({});
  const [archiveData, setArchiveData] = useState([]);
  const [infoIsActive, setInfoIsActive] = useState(false);
  const [announcementText, setAnnouncementText] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [obsStreamingInstructions, setObsStreamingInstructions] = useState({});
  const [activeLivestream, setActiveLivestream] = useState({});
  const [room2, setRoom2] = useState({});
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const [remoteIsBroadcasting, setRemoteIsBroadcasting] = useState(false);
  const [isListeningToRoom1, setIsListeningToRoom1] = useState(false);
  const [passwordProtectedText, setPasswordProtectedText] = useState({});
  const [siteOptions, setSiteOptions] = useState({});
  const [youAreStreaming, setYouAreStreaming] = useState(false);
  const [youAreConnecting, setYouAreConnecting] = useState(false);
  const [youHaveFinishedStreaming, setYouHaveFinishedStreaming] = useState(false);
  const [myStreamPlaybackId, setMyStreamPlaybackId] = useState('');
  const [socket] = useState(io(ENDPOINT, {
    withCredentials: true,
    extraHeaders: {
      "my-custom-header": "my-custom-header"
    }
  }));

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleFetchLivestreams = (response) => {
      if (response[0]) {
        for (let livestream of response) {
          if (livestream.playbackId && livestream.finished !== true) {
            setActiveLivestream({...livestream });
          }
        }
      }
    }
    fetchCockpitData('archive', handleFetchLivestreams, true, {
      playbackId: true,
      title: true,
      image: true,
      startTime: true,
      endTime: true,
      finished: true,
      _id: false
    }, { active: true });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    fetchCockpitData('info', setInfoData, false);
    fetchCockpitData('djApplicationPage', setDjApplicationPage, false);
    fetchCockpitData('obsStreamingInstructions', setObsStreamingInstructions, false);
    fetchCockpitData('passwordProtectedText', setPasswordProtectedText, false);
    const handleFetchArchiveData = (data) => {
      if (data) {
        if (data[0]) {
          setArchiveData(data.reverse());
        }
      }
    }
    fetchCockpitData('archive', handleFetchArchiveData, true, null, { published: true });
    const handleFetchAnnouncementsData = (response) => {
      if (response) {
        if (response[0]) {
          setAnnouncementText(response[response.length - 1].text);
        }
      }
    };
    fetchCockpitData('announcements', handleFetchAnnouncementsData, true);

    const handleFetchRoom2Data = (response) => {
      if (response) {
        if (response[0]) {
          setRoom2(response[response.length - 1]);
        }
      }
    };
    fetchCockpitData('room2', handleFetchRoom2Data, true, null, { isLive: true });
    fetchCockpitData('siteOptions', setSiteOptions, false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      smokingAreaIsActive={smokingAreaIsActive}
      setSmokingAreaIsActive={setSmokingAreaIsActive}
      announcementText={announcementText}
      setAnnouncementText={setAnnouncementText}
      room2={room2}
      setRoom2={setRoom2}
      windowWidth={windowDimensions.windowWidth}
      windowHeight={windowDimensions.windowHeight}
      infoIsActive={infoIsActive}
      setInfoIsActive={setInfoIsActive}
      infoData={infoData}
      djApplicationPage={djApplicationPage}
      archiveData={archiveData}
      cookiesAreAccepted={cookiesAreAccepted}
      setCookiesAreAccepted={setCookiesAreAccepted}
      isBroadcasting={isBroadcasting}
      setIsBroadcasting={setIsBroadcasting}
      remoteIsBroadcasting={remoteIsBroadcasting}
      setRemoteIsBroadcasting={setRemoteIsBroadcasting}
      isListeningToRoom1={isListeningToRoom1}
      setIsListeningToRoom1={setIsListeningToRoom1}
      activeLivestream={activeLivestream}
      setActiveLivestream={setActiveLivestream}
      passwordProtectedText={passwordProtectedText}
      obsStreamingInstructions={obsStreamingInstructions}
      socket={socket}
      streamKey={streamKey}
      setStreamKey={setStreamKey}
      youAreStreaming={youAreStreaming}
      setYouAreStreaming={setYouAreStreaming}
      youAreConnecting={youAreConnecting}
      setYouAreConnecting={setYouAreConnecting}
      youHaveFinishedStreaming={youHaveFinishedStreaming}
      setYouHaveFinishedStreaming={setYouHaveFinishedStreaming}
      myStreamPlaybackId={myStreamPlaybackId}
      setMyStreamPlaybackId={setMyStreamPlaybackId}
      siteOptions={siteOptions}
    />
  );
}

export default App;
