import { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import Marquee from 'react-marquee-slider';
import times from "lodash/times";

const VideoPlayerShow = (props) => {

  const { url, noControls, isLooping, room2 } = props;
  const { title, startTime, endTime } = room2;
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoUiIsHidden, setVideoUiIsHidden] = useState(false);
  const videoPlayerMiniInactivityTimeout = useRef();
  const wrapper = useRef();

  const player = useRef();

  const onInactivityTimeoutCompleted = () => {
    setVideoUiIsHidden(true);
  }

  const handleInteract = (e) => {
    clearTimeout(videoPlayerMiniInactivityTimeout.current);
    setVideoUiIsHidden(false);
    videoPlayerMiniInactivityTimeout.current = setTimeout(onInactivityTimeoutCompleted, 3000);
    if (noControls === true && e._reactName === 'onClick') {
      setIsPlaying(!isPlaying);
    }
  }

  const handleFullScreen = (e) => {
    typeof e !== 'undefined' && e.preventDefault();
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

  if (url) {
    return (
      <div
        className="video-player__wrapper"
        ref={wrapper}
      >
        <div
          className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}`}
          onClick={handleInteract}
          onTouchStart={handleInteract}
          onMouseMove={handleInteract}
        >
          <div
            className="video__wrapper"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            {
              1 === 2 &&
              <button
                className="button--fullscreen"
                aria-label="view fullscreen"
                onClick={handleFullScreen}
              ></button>
            }
            <ReactPlayer
              ref={player}
              url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
              className="video-player__player"
              playsinline={true}
              playing={isPlaying}
              volume={1}
              muted={false}
              height={0}
              width={'100%'}
              loop={isLooping}
              onPlay={() => { setIsPlaying(true) }}
              onPause={() => { setIsPlaying(false) }}
            />
          </div>
        </div>
        <AnimatePresence>
          {
            (videoUiIsHidden === false || isPlaying === false) && noControls !== true &&
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: .2 }}
              className="listen__wrapper--video player__controls__inner listen__wrapper active"
            >
              <button
                className={`listen__button${isPlaying === true ? ' active' : ''}`}
                onClick={(e) => {
                  typeof e !== 'undefined' && e.preventDefault();
                  setIsPlaying(!isPlaying);
                }}
              />
              <div
                className="header__marquee__wrapper"
              >
                <Marquee
                  velocity={12}
                  direction={'rtl'}
                >
                  {
                    12 &&
                    times(12, Number).map(
                      i => (
                        <div
                          className="work__item__inner"
                          key={i}
                        >
                          {
                            title && startTime && endTime &&
                            <p className="announcement">{title} {startTime}—{endTime}</p>
                          }
                        </div>
                      )
                    )
                  }
                </Marquee>
              </div>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    )
  } else {
    return null;
  }
}

export default VideoPlayerShow;