import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Password from '../components/GreenRoom/Password';
import { cockpitUrl } from '../utils/cockpit';
import LazyloadImage from '../components/LazyloadImage';
import TracklistFormInputSection from '../components/WheelsOfSteel/TracklistFormInputSection';
import GenresFormInputSection from '../components/WheelsOfSteel/GenresFormInputSection';

const RecordingUploader = (props) => {

  const { windowHeight } = props;

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
  const [uploadIsProcessing, setUploadIsProcessing] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [instagramValue, setInstagramValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [tracklist, setTracklist] = useState([{ 'artist': '', 'track': '' }]);
  const [genres, setGenres] = useState(['']);
  const PATH = '/api/cockpit/addAssets?token=';
  const apiKey = process.env.REACT_APP_API_KEY;
  const api = cockpitUrl + PATH + apiKey;

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB SHOW INFO FORM';
  }, []);

  const uploadImageElement = useRef();

  const createPost = (e) => {

    e.preventDefault();

    if (imagePath !== '') {
      const tracklistArray = [];

      for (let i = 0; i < tracklist.length; i++) {
        if (tracklist[i].track !== '' || tracklist[i].artist !== '') {
          tracklistArray.push(tracklist[i]);
        }
      }

      const genresArray = [];

      for (let i = 0; i < genres.length; i++) {
        if (genres[i] !== '') {
          genresArray.push(genres[i]);
        }
      }

      setUploadIsProcessing(true);
      fetch(`${cockpitUrl}/api/collections/save/archive?token=${apiKey}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            title: nameValue,
            date: new Date(),
            socialLink: `https://instagram.com/${instagramValue.replace('@', '')}/`,
            socialLinkText: `@${instagramValue.replace('@', '')}`,
            image: {
              path: `/storage/uploads${imagePath}`
            },
            tracklist: {
              tracklist: [...tracklistArray]
            },
            description: descriptionValue,
            genres: genresArray,
            published: false
          }
        })
      })
        .then(res => res.json()).then(() => {
          setUploadIsProcessing(false);
          setUploadSuccess(true);
          setUploadFailed(false);
        })
        .catch(error => {
          console.log(error);
          setUploadFailed(true);
          setUploadIsProcessing(false);
        });
    }
  }

  const handleUploadImage = (e) => {
    e.preventDefault();
    setUploadIsProcessing(true);
    const fileInput = uploadImageElement.current;
    const formData  = new FormData();

    formData.append('files[]', fileInput.files[0]);

    fetch(api, {
      method : 'POST',
      body : formData
    })
    .then(e=>e.json())
    .then(response=>{
      const { assets } = response;
      if (assets) {
        if (assets[0]) {
          setImagePath(assets[0].path);
          setUploadFailed(false);
          setUploadIsProcessing(false);
        }
      }
    })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
      className="dj-booth"
    >
      {
        passwordIsCorrect === true ?
          <div className="dj-booth__content">
            <form
              onSubmit={createPost}
            >
              {
                uploadSuccess === true &&
                <p className="upload-form__success">Thank you angel</p>
              }
              {
                uploadFailed === true &&
                <p className="upload-form__error">Error! Please try again</p>
              }
              {
                uploadIsProcessing === true && uploadSuccess === false &&
                <p className="upload-form__loading">Your upload is processing, please be patient 😘</p>
              }
              {
                uploadSuccess === false &&
                <div>
                <label className="upload-form__label artwork-generator__form__label" htmlFor="name">The name of your show</label>
                <input
                  className="upload-form__input artwork-generator__form__input"
                  type="text"
                  name="name"
                  value={nameValue}
                  onChange={(e) => { setNameValue(e.target.value); }}
                />
                {
                  imagePath === '' ?
                    <div>
                      <label
                        htmlFor="image"
                        className="artwork-generator__form__label"
                      >Add your show’s image</label>
                      <input
                        required
                        className="artwork-generator__form__input"
                        type="file"
                        ref={uploadImageElement}
                        name="image"
                        onChange={handleUploadImage}
                        accept="image/*"
                      />
                    </div>
                    :
                    <LazyloadImage image={{ path: `/storage/uploads${imagePath}` }} alt="Your show’s image" w={320} />
                  }
                  <label
                    className="upload-form__label
                    artwork-generator__form__label"
                    htmlFor="instagram"
                  >Your Instagram @ if you want to be linked to!</label>
                  <input
                    className="upload-form__input artwork-generator__form__input"
                    type="text"
                    name="instagram"
                    value={instagramValue}
                    onChange={(e) => { setInstagramValue(e.target.value); }}
                  />
                  <label
                    className="upload-form__label
                    artwork-generator__form__label"
                    htmlFor="description"
                  >Description</label>
                  <input
                    className="upload-form__input artwork-generator__form__input"
                    type="text"
                    name="description"
                    value={descriptionValue}
                    onChange={(e) => { setDescriptionValue(e.target.value); }}
                  />
                    <TracklistFormInputSection {...props} tracklist={tracklist} setTracklist={setTracklist} />
                    <GenresFormInputSection {...props} genres={genres} setGenres={setGenres} />
                  <input type="submit" name="submit" className="upload button button--margin-top" value="submit" />
                </div>
              }
            </form>
          </div>
          :
          <Password {...props} setPasswordIsCorrect={setPasswordIsCorrect} />
      }
    </motion.div>
  )
}

export default RecordingUploader;