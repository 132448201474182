import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import UI from './UI';
import SmokingArea from './SmokingArea';
import Info from './Info';
import Socket from '../utils/Socket';
import Room1 from '../views/Room1';
import Room2 from '../views/Room2';
import WheelsOfSteel from '../views/WheelsOfSteel';
import Room2Booth from '../views/Room2Booth';
import Archive from '../views/Archive';
import Show from '../views/Show';
import CookiesNotice from './CookiesNotice';
import GreenRoom from '../views/GreenRoom';
import RecordingUploader from '../views/RecordingUploader';
import ApplyToPlayAShow from '../views/ApplyToPlayAShow';
import Listen from './Listen';
import ShowArtwork from '../views/ShowArtwork';

const Content = (props) => {

  const { windowWidth, windowHeight, isLoading, smokingAreaIsActive, infoIsActive, archiveData, cookiesAreAccepted, setCookiesAreAccepted, location, room2 } = props;

  return (
    <div
      className={`wrapper${isLoading === true ? ' loading' : ''}`}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      {
        isLoading === true &&
        <p className="loading-text">It’s still loading babes please be patient x</p>
      }
      <main className={`content${isLoading === true ? ' loading' : ''}`}>
        <Socket {...props} />
        <UI {...props} />
        {
          location.pathname !== '/wheels-of-steel' &&
          location.pathname !== '/room-2' &&
          location.pathname.indexOf('/show/') !== 0 &&
          <Listen {...props} />
        }
        <AnimatePresence exitBeforeEnter initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path={'/wheels-of-steel'} element={
              <WheelsOfSteel {...props} key={'WheelsOfSteel'} />
            } />
            <Route path={'/green-room'} element={
              <GreenRoom {...props} key={'greenRoom'} />
            } />
            <Route path={'/submit'} element={
              <RecordingUploader {...props} key={'recording uploader'} />
            } />
            <Route path={'/apply'} element={
              <ApplyToPlayAShow {...props} key={'applyToPlayAShow'} />
            } />
            <Route path={'/room-2-booth'} element={
              <Room2Booth {...props} key={'room2Booth'} />
            } />
            {
              room2.isLive === true &&
              <Route path={'/room-2'} element={
                <Room2 {...props} key={'room2'} />
              } />
            }
            <Route path={'/archive'} element={
              <Archive {...props} key={'archive'} />
            } />
            {
              archiveData &&
              archiveData[0] &&
              archiveData.map(
                (item, index) => (
                  <Route path={`/show/${item._id}`} key={index} element={
                    <Show {...props} item={item} index={index} />
                  } />
                )
              )
            }
            {
              archiveData &&
              archiveData[0] &&
              archiveData.map(
                (item, index) => (
                  <Route path={`/artwork/${item._id}`} key={index} element={
                    <ShowArtwork {...props} item={item} index={index} />
                  } />
                )
              )
            }
            <Route path={"*"} element={
              <Room1 {...props} key={'room1'} />
            } />
          </Routes>
        </AnimatePresence>
        <AnimatePresence>
          {
            infoIsActive === true &&
            <Info {...props} key={'info'} />
          }
          {
            smokingAreaIsActive === true &&
            <SmokingArea {...props} key={'smokingArea'} />
          }
        </AnimatePresence>
      </main>
      {
        cookiesAreAccepted === false &&
        <CookiesNotice handleAcceptCookies={() => setCookiesAreAccepted(true)} />
      }
    </div>
  )
};

export default Content;