import { useState, useEffect } from 'react';
import { fetchCockpitData } from '../utils/cockpit';
import WheelsOfSteelContent from '../components/WheelsOfSteel/WheelsOfSteelContent';

const WheelsOfSteel = (props) => {

  const [streamName, setStreamName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
  const [wheelsOfSteelInfo, setwheelsOfSteelInfo] = useState({});
  const [descriptionValue, setDescriptionValue] = useState('');

  useEffect(() => {
    if (passwordIsCorrect === true) {
      fetchCockpitData('wheelsOfSteelInfo', setwheelsOfSteelInfo, false);
    }
  }, [passwordIsCorrect]);

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB WHEELS OF STEEL';
  }, []);

  return (
    <WheelsOfSteelContent
      {...props}
      streamName={streamName}
      setStreamName={setStreamName}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      passwordIsCorrect={passwordIsCorrect}
      setPasswordIsCorrect={setPasswordIsCorrect}
      wheelsOfSteelInfo={wheelsOfSteelInfo}
      descriptionValue={descriptionValue}
      setDescriptionValue={setDescriptionValue}
    />
  )
}

export default WheelsOfSteel;