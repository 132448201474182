import { useEffect } from 'react';
import { motion } from 'framer-motion';
import MirrorBall from '../components/MirrorBall';
import HelmetContent from '../utils/HelmetContent';

const Room1 = (props) => {

  const { windowHeight } = props;

  useEffect(() => {
    document.title = 'imissmymates.club';
  }, []);

  return (
    <motion.div
      className="room-1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={`Room 1`}
      />
      <MirrorBall {...props} />
    </motion.div>
  )
}

export default Room1;