import Marquee from "react-marquee-slider";
import times from "lodash/times";

const HeaderMarquee = (props) => {

  const { announcementText } = props;

  return (
    <div
      className="header__marquee__wrapper"
    >
      <Marquee
        velocity={12}
        direction={'rtl'}
      >
        {
          12 &&
          times(12, Number).map(
            i => (
              <div
                className="work__item__inner"
                key={i}
              >
                <p className="announcement">{announcementText}</p>
              </div>
            )
          )
        }
      </Marquee>
    </div>
  )
}

export default HeaderMarquee;