import { useState, useEffect } from 'react';

const ENDPOINT = 'https://broadcast.imissmymates.club';
// const ENDPOINT = "http://127.0.0.1:4000";

const Socket = (props) => {

  const {
    setAnnouncementText,
    setRoom2,
    isBroadcasting,
    isListeningToRoom1,
    socket,
    setStreamKey,
    activeLivestream,
    setActiveLivestream,
    myStreamPlaybackId,
    youAreStreaming,
    setMyStreamPlaybackId,
    setYouAreStreaming,
    setYouAreConnecting,
    setYouHaveFinishedStreaming
  } = props;

  const [isInitialised, setIsInitialised] = useState(false);

  if (isBroadcasting || isListeningToRoom1) { }

  useEffect(() => {
    const handleLivestreamConnected = (data) => {
      if (youAreStreaming === false) {
        if (data.playbackId === myStreamPlaybackId) {
          console.log('you are connecting');
          setYouAreConnecting(true);
        } else {
          console.log('you are not connecting');
          setYouAreConnecting(false);
        }
      }
    }

    socket.addEventListener('livestream connected', handleLivestreamConnected);

    return () => {
      socket.removeEventListener('livestream connected', handleLivestreamConnected);
    }
  }, [myStreamPlaybackId, socket, setYouAreConnecting, youAreStreaming]);

  useEffect(() => {
    if (youAreStreaming === true) {
      setYouAreConnecting(false);
    }
  }, [youAreStreaming, setYouAreConnecting]);

  useEffect(() => {
    if (activeLivestream.playbackId === myStreamPlaybackId) {
      console.log('you are streaming');
      setYouAreStreaming(true);
    } else {
      console.log('you are not streaming');
      setYouAreStreaming(false);
    }
  }, [myStreamPlaybackId, activeLivestream.playbackId, setYouAreStreaming]);

  useEffect(() => {
    const handleStreamEnded = (data) => {
      if (youAreStreaming === true) {
        setYouHaveFinishedStreaming(true);
        setYouAreStreaming(false);
        setMyStreamPlaybackId('');
        setStreamKey('');
      }
    }

    socket.addEventListener('livestream ended', handleStreamEnded);

    return () => {
      socket.removeEventListener('livestream ended', handleStreamEnded);
    }
  }, [youAreStreaming, setYouAreStreaming, socket, setYouHaveFinishedStreaming, setMyStreamPlaybackId, setStreamKey]);

  useEffect(() => {

    const handleStreamStarted = (data) => {
      console.log('stream started');
      console.log(data)
      setActiveLivestream({ ...data });
      setYouAreConnecting(false);
    }

    const handleStreamEnded = (data) => {
      console.log('stream ended');
      setActiveLivestream({});
    }

    socket.addEventListener('livestream started', handleStreamStarted);
    socket.addEventListener('livestream ended', handleStreamEnded);

    return () => {
      socket.removeEventListener('livestream started', handleStreamStarted);
      socket.removeEventListener('livestream ended', handleStreamEnded);
    }
  }, [socket, setActiveLivestream, setYouAreStreaming, setYouAreConnecting]);

  useEffect(() => {
    if (isInitialised === false) {
      setIsInitialised(true);
      socket.connect();

      socket.on('announcement changed', data => {
        if (data) {
          if (data.args) {
            if (data.args[1]) {
              if (data.args[1].text) {
                setAnnouncementText(data.args[1].text);
              }
            }
          }
        }
      });

      socket.on('room2 changed', data => {
        if (data) {
          if (data.args) {
            if (data.args[1]) {
              if (data.args[1].isLive === true) {
                setRoom2(data.args[1]);
              } else {
                setRoom2({});
              }
            }
          }
        }
      });

      socket.on('stream created', data => {
        setStreamKey(data.streamKey);
        setMyStreamPlaybackId(data.playbackId);
      });

      socket.on('you are streaming', data => {
        setYouAreStreaming(true);
      });

      socket.on('you are no longer streaming', data => {
        setYouAreStreaming(false);
      });
    }
    return () => {
      // socket.disconnect();
    };
  }, [socket, isInitialised, setAnnouncementText, setRoom2, setYouAreStreaming, setStreamKey, setMyStreamPlaybackId]);

  return null;
}

export default Socket;
export { ENDPOINT };