import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { saveCollectionEntry } from '../utils/cockpit';
import parse from 'html-react-parser';
import HelmetContent from '../utils/HelmetContent';

const ApplyToPlayAShow = (props) => {

  const { windowHeight, djApplicationPage } = props;

  const [success, setSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [failed, setFailed] = useState(false);
  const [applicantNameValue, setApplicantNameValue] = useState('');
  const [showNameValue, setShowNameValue] = useState('');
  const [instagramValue, setInstagramValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const createPost = (e) => {

    e.preventDefault();

    const data = {
      nameOfApplicant: applicantNameValue,
      nameOfShow: showNameValue,
      instagram: instagramValue,
      description: descriptionValue
    }

    saveCollectionEntry(
      'djApplications',
      data,
      () => {
        setSuccess(true);
        setFailed(false);
      }, (error) => {
        console.log(error);
        setFailed(true);
        setIsProcessing(false);
      }
    );
  }

  useEffect(() => {
    document.title = 'I MISS MY MATES CLUB APPLY';
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: windowHeight + 'px'
      }}
      className="dj-booth"
    >
      <HelmetContent
        {...props}
        url={props.location.pathname}
        title={`Apply to play a show`}
      />
      <div className="dj-booth__content">
        {
          djApplicationPage.formIntroText && success === false && isProcessing === false &&
          <div
            className="form__intro-text"
            style={{ marginBottom: '12px' }}
          >
            {parse(djApplicationPage.formIntroText)}
          </div>
        }
        <form
          onSubmit={createPost}
        >
          {
            success === true &&
            <p className="upload-form__success">Thank you angel</p>
          }
          {
            failed === true &&
            <p className="upload-form__error">Error! Please try again</p>
          }
          {
            isProcessing === true && success === false &&
            <p className="upload-form__loading">Your upload is processing, please be patient love</p>
          }
          {
            success === false &&
            <div>
              <label className="upload-form__label artwork-generator__form__label" htmlFor="name">Your name/DJ alias</label>
              <input
                required
                className="upload-form__input artwork-generator__form__input"
                type="text"
                name="name"
                value={applicantNameValue}
                onChange={(e) => { setApplicantNameValue(e.target.value); }}
              />
              <label className="upload-form__label artwork-generator__form__label" htmlFor="showName">The name of your show</label>
              <input
                required
                className="upload-form__input artwork-generator__form__input"
                type="text"
                name="showName"
                value={showNameValue}
                onChange={(e) => { setShowNameValue(e.target.value); }}
              />
              <label
                className="upload-form__label
                artwork-generator__form__label"
                htmlFor="instagram"
              >Your Instagram @ so I can contact you! If you don’t have one pop in your email instead.</label>
                <input
                required
                className="upload-form__input artwork-generator__form__input"
                type="text"
                name="instagram"
                value={instagramValue}
                onChange={(e) => { setInstagramValue(e.target.value); }}
              />
              <label
                className="upload-form__label
                artwork-generator__form__label"
                htmlFor="description"
              >A description of your show: whether you’d like to play live or pre record it, what kind of music you want to play, if you’ll be talking, if you’d like it to be a one-off or recurring, what sorts of times/dates would suit you if you want to play live, what kind of computer/software/hardware you plan to use (literally can be anything whether it’s a decks or YouTube)</label>
                <textarea
                  rows={4}
                className="upload-form__input artwork-generator__form__input"
                type="text"
                name="description"
                value={descriptionValue}
                onChange={(e) => { setDescriptionValue(e.target.value); }}
              />
              <input type="submit" name="submit" className="upload button button--margin-top" value="Submit" />
            </div>
          }
        </form>
      </div>
    </motion.div>
  )
}

export default ApplyToPlayAShow;